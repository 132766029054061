<template>

    <div ref="inProcess" id="projects-in-process">
        <div class="vx-col w-full mb-6">
            <vx-card class="big-title-card-bg-black h-auto">
                <h1 class="rg-big-title extrabold rg-title">
                    Proyectos en <span>proceso</span>.
                </h1>
            </vx-card>
        </div>

        <!-- OVERLAY CARD -->
        <div class="vx-col w-full mb-6">
            <vx-card title="Da seguimiento a tus proyectos.">
                <p>Aquí podrás mantenerte al tanto del avance de tus proyectos, consultar el estado de manera rápida de todas sus fases y visualizar los documentos relacionados a este.</p>
            </vx-card>
        </div>


        <!-- TABLE -->
        <div class="col-md-12 mb-4">
            <vx-card>
                <vs-table stripe max-items="10" :data="getProjectsFiltered" pagination search noDataText="No hay datos disponibles">
                    <div slot="header">
                        <p class="bold mb-4 mt-4 pl-2">Filtros</p>
                        <div class="flex pl-2">
                            <vs-select
                                v-if="programIdSelected !== null"
                                label="Zonas"
                                v-model="zoneIdSelected"
                                @change="addFilter('zone_id', $event)"
                                class="mr-2">
                                <vs-select-item text="Todas" value="0"></vs-select-item>
                                <vs-select-item
                                    v-for="zone in programSelected.zones"
                                    :text="zone.name"
                                    :key="zone.id"
                                    :value="zone.id"></vs-select-item>
                            </vs-select>

                            <vs-select
                                :disabled="zoneIdSelected === 0 && zoneSelected.regions.length < 0"
                                label="Regiones"
                                v-model="regionIdSelected"
                                @change="addFilter('region_id', $event)">
                                <vs-select-item text="Todas" value="0"></vs-select-item>
                                <vs-select-item
                                    v-for="region in zoneSelected.regions"
                                    :text="region.name"
                                    :key="region.id"
                                    :value="region.id"></vs-select-item>
                            </vs-select>
                            <vs-button @click.stop="getProjectsList()" type="filled" class="mt-6 ml-4" icon="search"></vs-button>
                            <vs-button v-if="isProgramAdmin" @click.stop="getCSV()" type="filled" color="dark" class="mt-6 ml-4" icon="file_download"></vs-button>
                        </div>
                        <vs-divider class="mb-4"></vs-divider>
                        
                        <h4 class="mb-4">Lista de proyectos.</h4>
                        <div class="flex">
                            <vs-button class="mb-4 mt-6" size="small" color="dark" @click="getProjectsList();" icon="replay">Actualizar</vs-button>
                             <vs-select
                                label="Filtro por estado"
                                v-model="statusSelected"
                                class="align-item w-full ml-3">
                                <vs-select-item
                                    v-for="status in projectStatus"
                                    :text="status.text"
                                    :key="status.value"
                                    :value="status.value"></vs-select-item>
                            </vs-select>
                            <!-- <vs-checkbox class="ml-6 mb-4" v-model="mainCheckbox">¿Mostrar proyectos entregados? Si/No, por default no.</vs-checkbox> -->
                        </div>
                    </div>
                    <template slot="thead">
                        <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td :data="data[indextr].pid">
                                <router-link to="#">
                                    {{tr.pid}}
                                </router-link>
                            </vs-td>
                            <vs-td>
                                <strong>{{tr.client.name}}</strong>
                                <br> {{tr.client.email}}
                                <br> {{phoneFormat(tr.client.phone)}}
                                <br> {{tr.client.alias}}
                            </vs-td>
                            <vs-td>
                                <strong>{{tr.potential_applicant.customer_uid}} | {{tr.potential_applicant.customer_name}}</strong>
                                <br> {{tr.zone ? tr.zone.name : "--"}} | {{ tr.region ? tr.region.name : "--" }}
                            </vs-td>
                            <vs-td v-if="tr.supplier != null">
                                <strong>{{tr.supplier.id}} | {{tr.supplier.tradename}}</strong>
                                <br> {{tr.supplier.email}}
                                <br> {{phoneFormat(tr.supplier.contact_phone_1)}}
                            </vs-td>
                            <vs-td v-else>
                                Sin proveedor
                            </vs-td>
                            <vs-td>
                                <span class="bold text-xs">{{tr.project_status}}</span>
                            </vs-td>
                            <vs-td>
                                <span class="bold text-xs">{{tr.percentageInvested.toFixed(2)}} %</span>
                            </vs-td>
                            <vs-td>
                                <div class="flex text-center cursor-pointer" @click="setCurrentProjectInfo(tr, 'contracts')" >
                                    <vs-chip transparent :color="setContractsTriage(tr.contracts).color">
                                        <span class="bold text-xs">{{setContractsTriage(tr.contracts).text}}</span>
                                    </vs-chip>
                                </div>
                            </vs-td>
                            <vs-td>
                                <div class="flex text-center cursor-pointer"  @click="setCurrentProjectInfo(tr, 'invoices')">
                                    <vs-chip transparent :color="setInvoiceTriage(tr.invoices)">
                                        <span class="bold text-xs">{{setInvoicesStatus(tr.invoices)}}</span>
                                    </vs-chip>
                                </div>
                            </vs-td>
                            <vs-td>
                                <div class="flex text-center cursor-pointer" @click="setCurrentProjectInfo(tr, 'contracts')">
                                    <vs-chip transparent :color="setContractsTriage(tr.annexed).color">
                                        <span class="bold text-xs">{{setContractsTriage(tr.annexed).text}}</span>
                                    </vs-chip>
                                </div>
                            </vs-td>
                            <vs-td>
                                <div class="flex text-center cursor-pointer" @click="setCurrentProjectInfo(tr, 'technology')" >
                                    <vs-chip transparent :color="setTriage(tr.serial_numbers)">
                                        <span class="bold text-xs">{{tr.serial_numbers}}</span>
                                    </vs-chip>
                                </div>
                            </vs-td>
                            <vs-td>
                                <div class="flex text-center cursor-pointer" @click="setCurrentProjectInfo(tr, 'evidences')">
                                    <vs-chip transparent :color="setTriage(tr.evidence_status)">
                                        <span class="bold text-xs">{{tr.evidence_status}}</span>
                                    </vs-chip>
                                </div>
                            </vs-td> 
                            <vs-td>
                                <div class="flex text-center cursor-pointer" @click="handleAction(tr)">
                                    <vs-chip transparent :color="setTriage(tr.survey_status)">
                                        <span class="bold text-xs">{{tr.survey_status}}</span>
                                    </vs-chip>
                                </div>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </vx-card>
        </div>

        <!-- ESTATUS DE CONTRATOS -->
        <vs-popup title="Estado de contratos" :active.sync="contractsPopUp">
            <contracts-control v-if="contractsPopUp" :contracts="contracts"/> 
        </vs-popup>

        <!-- ESTATUS DE CONTRATOS -->
        <vs-popup title="Facturas" :active.sync="popupActivoInvoices">
            <vx-card class="mb-6" title="Detalle de facturas.">
                <p>En este apartado podrás verificar el estado de las facturas del proyecto para corroborar la información de la tecnología utilizada en el proceso de instalación y entrega del proyecto. Si deseas consultar más información sobre estos documentos, contacta a RedGirasol.</p>
            </vx-card>
            <invoices-control v-if="popupActivoInvoices" :project_id="currentProjectId" :isDisabled="true"/> 
        </vs-popup>

        <!-- TECNOLOGÍAS -->
        <vs-popup title="Tecnologías del proyecto" :active.sync="popupActivoTechnology" fullscreen>
            <technologies-control v-if="popupActivoTechnology" :projectFgLocations="projectFgLocations" /> 
        </vs-popup>

        <!-- EVIDENCIAS -->
        <vs-popup title="Evidencias del proyecto" :active.sync="evidencesPopUp" fullscreen>
            <evidences-control v-if="evidencesPopUp" :projectFgLocations="projectFgLocations" /> 
        </vs-popup>

        <!-- MODAL ENCUESTA -->
        <vs-popup title="Encuestas de satisfacción" :active.sync="popupActivoSurvey">
            <survey-control v-if="popupActivoSurvey" :project_id="currentProjectId" :client_id="currentClientId"/>
        </vs-popup>

    </div>

</template>

<script>
import formatHelper from "@mixins/formatHelper";
import ContractsControl from '../kof/components/ContractsControl.vue';
import InvoicesControl from '../kof/components/InvoicesControl.vue';
import TechnologiesControl from '../kof/components/TechnologiesControl.vue';
import EvidencesControl from '../kof/components/EvidencesControl.vue';
import SurveyControl from '../kof/components/SurveyControl.vue';
// import ProjectEvidencesRevisionTab from '@components/projectsv2/ProjectEvidencesRevisionTab.vue';
// import InvoicesControl from '@projects/InvoicesControl.vue';

const columns = [
    { title: "PID", key: "pid", sortable: true }
    , { title: "SOLICITANTE", key: "client", sortable: true }
    , { title: "PROSPECTO", key: "lead", sortable: true }
    , { title: "INSTALADOR", key: "supplier", sortable: true }
    , { title: "ESTADO DE PROYECTO", key: "project_status", sortable: false }
    , { title: "FONDEO", key: "funding", sortable: false }
    , { title: "ESTADO DE CONTRATOS", key: "contracts_status", sortable: false }
    , { title: "FACTURAS DE PROYECTO", key: "project_invoices", sortable: false }
    , { title: "ESTADO DE ANEXOS", key: "anexo_status", sortable: false }
    , { title: "TECNOLOGÍA", key: "serial_numbers", sortable: false }
    , { title: "EVIDENCIAS", key: "evidencias", sortable: false }
    , { title: "ENCUESTA", key: "survey", sortable: false }
    // , { title: "TT (días)", key: "tt", sortable: false }
    // , { title: "ANTICIPO", key: "installation_date", sortable: false }
];

const contractsStatus = [
    { value: "created", text: "Creados" }
    , { value: "sent", text: "Enviados" }
    , { value: "signed", text: "Firmados" }
    , { value: "verified", text: "Verificados" }
    , { value: "not_created", text: "Sin generar" }
];

const evidenceStatus = [
    { value: "Pendientes", text: "Pendientes" }
    , { value: "Incompletas", text: "Incompletas" }
    , { value: "En revisión", text: "En revisión" }
    , { value: "Verificadas", text: "Verificadas" }
];

const surveyStatus = [
    { value: "Sin Generar", text: "Sin generar" }
    , { value: "Sin contestar", text: "Sin contestar" }
    , { value: "Contestada", text: "Contestada" }
];

const projectStatus = [
    { value: "-1", text: "Todos" }
    , { value: "3", text: "Pre-aprobado" }
    , { value: "4", text: "Aprobado" }
    , { value: "5", text: "En fondeo" }
    , { value: "6", text: "Firma de contratos" }
    , { value: "7", text: "En instalación" }
    , { value: "8", text: "Entregado" }
];

const TTOptions = [
    { value: "0.10", text: "de 0 a 10 días" }
    , { value: "11.20", text: "de 11 a 20 días" }
    , { value: "21.30", text: "de 21 a 30 días" }
    , { value: "30.0", text: "Más de 30 días" }
];

const spQuestionIds = [64,65,66];

export default {
    name: "projects-in-process",
    mixins: [formatHelper],
    props: {
        currentStatus: { type: [Number, String, Array], required: false, default: null },
        currentZoneId: { type: [Number, String], required: false, default: 0 },
        currentRegionId: { type: [Number, String], required: false, default: 0 },
        isFromPopUp: { type: Boolean, required: false, default: false },
    },
    data(){
        return {
            projects: []
            , projectsFiltered: []
            , columns: columns
            , filterType: [false,false,false,false,false,false]
            , contractsStatus: contractsStatus
            , evidenceStatus: evidenceStatus
            , surveyStatus: surveyStatus
            , projectStatus: projectStatus
            , TTOptions: TTOptions
            , filter: []
            , project: {}
            , contractsPopUp: false
            , evidencesPopUp: false
            , projectSelected: {}
            , popupActivoSurvey: false
            , survey: []
            , spQuestionIds: spQuestionIds
            , popupActivoInvoices: false
            , currentProjectId: null
            , currentClientId: null
            , popupActivoTechnology: false
            , projectFgLocations: []
            , filters: {}
            , programs: []
            , zoneIdSelected: 0
            , regionIdSelected: 0
            , mainCheckbox: false
            , statusSelected: -1
        }
    },
    components: {
        ContractsControl
        , InvoicesControl
        , TechnologiesControl
        , EvidencesControl
        , SurveyControl
    },
    async mounted(){
        if(this.isFromPopUp){
            this.getProjectsList();
        }
        const {data} = await axios.get(`/api/pgm/me/programs`)
        this.programs = data;
    },
    computed: {
        isProgramAdmin() {
            return this.$acl.check("isProgramAdmin");
        },
        showFilterButton(){
            let i = 0;
            let arrLength = this.filterType.length;

           for (i; i < arrLength; i++) {
               if(this.filterType[i]){
                   return true;
               }
           }

           return false;
        },
        programSelected() {
            let program = this.programs.filter(p => p.id === this.programIdSelected)
            return program[0] ?? null;
        },
        zoneSelected() {
            let zone = this.programSelected.zones.filter(z => z.id === this.zoneIdSelected)
            return zone[0] ?? {regions: []};
        },
        getProjectsFiltered(){
            if(this.statusSelected == -1){
                return this.projectsFiltered;
            }

            if(this.projectsFiltered.length > 0)
            {
                return this.projectsFiltered.filter(x => x.status == this.statusSelected);
            }

            return [];
        },
        programIdSelected () {
            return this.currentProgramData.id;
        }
    },
    methods: {
        async getProjectsList(){
            this.showLoading(true);
            if(this.isFromPopUp){
                this.zoneIdSelected = this.currentZoneId;
                this.regionIdSelected = this.currentRegionId;
            }
            try {
                const res = await axios.get(`/api/kof/get/projects/in-process?region=${this.regionIdSelected}&programId=${this.currentProgramData.id}&project_status[]=${this.currentStatus}`);
                this. filter = [];
                this.filterType = [false,false,false,false,false,false];
                if(res.data.length == undefined){
                    this.projects.push(res.data["1"]);
                    this.projectsFiltered.push(res.data["1"]);
                } else {
                    this.projects = res.data;
                    this.projectsFiltered = res.data;
                }
            } catch (error) {
               this.warn(error); 
            }
            this.showLoading(false);
        },
        setTriage(status){
            let colorClass = "primary";
            if(status === "Verificadas" || status === "Contestada"|| status === "Completas"){
                colorClass = "success";
            } else if(status === "En revisión") {
                colorClass = "warning";
            } else if(status === "Incompletas" || status === "Sin contestar"){
                colorClass = "danger";
            } else if (status === "Sin Generar" || status === "Pendientes") {
                colorClass = "dark";
            }

            return colorClass;
        },
        setContractsTriage(contracts){
            let res = {
                color: "primary"
                , text: "Sin Generar"
                };

            if(contracts !== null && contracts.length !== 0){
                let statusContract = contracts[0].status;
                switch(statusContract){
                    case "generated":
                        res.color = "dark";
                        res.text = "Generados";
                        break;
                    case "sent":
                        res.color = "warning";
                        res.text = "Enviados";
                        break;
                    case "signed":
                        res.color = "success";
                        res.text = "Firmados";
                        break;
                    case "canceled":
                        res.color = "danger";
                        res.text = "Cancelados";
                        break;
                    case "verified":
                        res.color = "success";
                        res.text = "Verificados";
                        break;
                }
            }

            return res;
        },
        setInvoicesStatus(invoices){
            let status = "Pendientes";
            if(invoices.length !== 0){
                let invoice = invoices[0];
                status = this.capitalizeFirstLetter(invoice.document_status);
            }

            return status;
        },
        setInvoiceTriage(invoices){
            let color = "dark";
            if(invoices.length !== 0){
                let invoice = invoices[0];
                let status = invoice.document_status;

                if(status === "rechazado"){
                    color = "danger";
                } else if(status === "verificado") {
                    color = "success";
                } else {
                    color = "warning";
                }
            }

            return color;
        },
        executeFilter(){
            let projects = this.projects;
            let i = 0 ;
            let arrLength = this.filterType.length;

            for(i; i <= arrLength; i++){
                if(this.filterType[i]){
                    switch(i){
                        case 0:
                            projects = this.filterProjects(projects, "status", this.filter[i]);
                        break;
                        case 1:
                            projects = this.filterByContracts(projects, this.filter[i], true);
                            break;
                        case 2:
                            projects = this.filterByContracts(projects, this.filter[i], false);
                            break;
                        case 3:
                            projects = this.filterProjects(projects, "evidence_status", this.filter[i]);
                        break;
                        case 4:
                            projects = this.filterProjects(projects, "survey_status", this.filter[i]);
                        break;
                        case 5:
                            projects = this.filterByTT(projects, this.filter[i]);
                        break;
                    }
                }
            }
            this.projectsFiltered = projects;
        },
        filterProjects(projects, field, filter){
            return projects.filter(x => x[field] === filter);
        },
        filterByTT(projects, filter){
            let collections;
            let ranges = filter.toString();
            if(ranges !== "30"){
                ranges += "0";
            }
            ranges = ranges.split(".");
            ranges = ranges.map(Number);

            if(ranges[0] === 30) {
                collections = projects.filter(x => x.project_tt > ranges[0]);
            } else {
                collections = projects.filter(x => (x.project_tt >= ranges[0] && x.project_tt <= ranges[1]));
            } 
            
            return collections;
        },
        filterByContracts(projects, filter, contractsType){
            let field = contractsType ? "contracts" : "annexed"
            let collection;
            if(filter === "not_created"){
                collection = projects.filter(x => x[field].length === 0);
            } else {
                collection = projects.filter(x => x[field][0] === filter);
            }

            return collection
        },
        async getProjectInfo(project_id){
            try {
                const response = await axios.get(`/api/v2/projects/${project_id}?with[]=splits&with[]=client.user&with[]=agent&with[]=finance&with[]=contracts&with[]=supplier.user`);
                this.project = response.data;
            } catch (error) {
                this.processError(error.status);
            }
        },
        async setCurrentProjectInfo(item, option){
            this.project = {};
            switch(option){
                case "contracts": 
                    this.contractsPopUp = true; 
                    this.contracts = item.contractsList
                    break;
                case "evidences":  
                    this.projectFgLocations = item.projectFgLocations;
                    this.evidencesPopUp = true; 
                    break;
                case "invoices":  
                    this.currentProjectId = item.pid;
                    this.popupActivoInvoices = true; 
                    break;
                case "technology":
                    this.projectFgLocations = item.projectFgLocations;
                    this.popupActivoTechnology = true; 
                    break;
                case "survey":
                    this.currentClientId = item.client.cid;
                    this.currentProjectId = item.pid;
                    this.popupActivoSurvey = true; 
                    break;
                default: return null;
            }
        },
        handleAction(item){
            if(item.status >= 7){
                this.setCurrentProjectInfo(item, 'survey')
            } else {
                 this.warningNotif(
                    "Encuesta de satisfacción"
                    , "Aún no es posible mandar la encuesta de satisfacción, es necesario que el proyecto esté en proceso de instalación."
                    , 15000
                );
            }
        },
        async addFilter(filterName, ev) {
            if (ev.toString() === "0") {
                delete this.filters[filterName];
            } else {
                this.filters[filterName] = ev.toString();
            }

            if(filterName == "zone_id" && ev.toString() === "0"){
                this.zoneIdSelected = 0;
                this.regionIdSelected = 0;
            }
        },
        getCSV() {
            try {
                let url = `${this.ApiDomain}/api/get/pgm-projects/csv?access_token=${this.AccessToken}`;
                window.open(url, '_blank');
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>

<style>
    .cursor-pointer {
        cursor: pointer !important;
    }
    .con-vs-dialog
    {
    z-index: 100000 !important;
    }

    #serial-numbers
    {
        z-index: 100000 !important;
    }

</style>