<template>
    <div>
        <div class="vx-row">
            <div class="vx-col w-full">
                <vx-card class="mb-6" title="Detalle de contratos.">
                    <p>En este apartado podrás verificar el estado de firma de contratos para continuar con el proceso de instalación y entrega del proyecto. Si deseas consultar más información sobre cuándo y cuáles contratos tienen que ser firmados, contacta a RedGirasol.</p>
                </vx-card>

                <vx-card>
                    <vs-table stripe :data="contractsShowed" noDataText="No hay datos disponibles">
                        <div slot="header">
                            <h4>Lista de contratos.</h4>
                        </div>
                        <template slot="thead">
                            <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td>
                                    {{tr.group.name}}
                                </vs-td>
                                <vs-td>
                                    <vs-chip color="primary">
                                        {{translateContractStatus(tr)}}
                                    </vs-chip>
                                </vs-td>
                                <vs-td>
                                    <vs-button 
                                        :disabled="!(tr.status == 'signed' || tr.status == 'verified')"
                                        size="small" class="mb-2 mr-3" target color="danger"
                                        @click="viewContractRequest(tr.group.id, tr.project_id)">
                                        Ver PDF<br>firmado
                                    </vs-button>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>

const columns = [
    { title: "NOMBRE", key: "name", sortable: false }
    , { title: "ESTADO", key: "status", sortable: false }
    , { title: "ACCIONES", key: "name", sortable: false }
];

const contractsInfo = [
  { group: "1", documents: "Contrato de Crédito, Pagaré, Prenda en garantía, Carta de entendimiento, Domiciliación", when_to_sign: "Durante el proceso de fondeo", required_for: "Anticipo", wish_to_sign: "Solicitante, Obligados Solidarios en caso de aplicar y RedGirasol"}
  , { group: "2", documents: "Contrato de Compra venta", when_to_sign: "Durante el proceso de fondeo", required_for: "Anticipo", wish_to_sign: "Proveedor, Solicitante y RedGirasol"}
  , { group: "3", documents: "Anexo 1 del Contrato de Crédito, Anexo 1.2 y Anexo 2 de la Prenda en garantía", when_to_sign: "Durante el proceso de instalación", required_for: "Finiquito", wish_to_sign: "Solicitante, Obligados Solidarios en caso de aplicar y RedGirasol"}
  , { group: "4", documents: "Anexo 1.2 del Contrato de Compra venta", when_to_sign: "Durante el proceso de instalación", required_for: "Finiquito", wish_to_sign: "Proveedor, Solicitante y RedGirasol"}
];

export default {
    name: "program-contracts",
    props: {
        contracts: { type: [Array], required: true }
    },
    data() {
        return {
            columns: columns
            , contractsShowed: []
        }
    }, 
    async mounted() {
        this.contracts.forEach( c => {
            this.checkContractList(c);
        });
    },
    computed: {
        contractList() {
            return
        }
    },
    methods:{
        checkContractList(contract) {
            if(this.contractsShowed.length === 0){
                this.contractsShowed.push(contract);
            } 
            
            if (!this.contractsShowed.some( x => x.group.id == contract.group.id)) {
                this.contractsShowed.push(contract);
            }
        },
        translateContractStatus(contract) {
            let text = "N/A";
            switch(contract.status){
                case "generated": text = "Generados"; break;
                case "sent": text = "Enviados"; break;
                case "signed": text = "Firmados"; break;
                case "verified": text = "Verificados"; break;
            }
            return text;
        },
        viewContractRequest(contract_group_id, project_id){
            let url = `${this.ApiDomain}/storagev3/documents/project/${project_id}/contracts/view/${contract_group_id}/?access_token=${this.AccessToken}&type=signed`
            try {
                window.open(url,url,'addressbar=0,directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=0,resizable=0,height=700,width=800');
            } catch (error) {
                
            }
        },
    }
}
</script>

<style>

</style>