<template>
    <div id="technologies-control">
        <div class="vx-col w-full mb-6">
            <vx-card title="Detalle de las tecnologías.">
                <p>Aquí puedes consultar las tecnologías utilizadas en la instalación de este proyecto; su información general, tiempo de vida y capacidad de ahorro por año.</p>
            </vx-card>
        </div>

        <div class="vx-col w-full mb-6">
            <vx-card>
                <vs-table stripe search :data="projectFgLocations" noDataText="No hay información">
                    <div slot="header">
                        <h4>Lista de instalaciones.</h4>
                    </div>
                    <template slot="thead">
                        <vs-th class="bold" v-for="(col, idx) in columnsPrincipal" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>
                                {{tr.family_group.name}}
                            </vs-td>
                            <vs-td>
                                {{tr.location.address.full_address}}
                            </vs-td>
                            <vs-td>
                                {{tr.supplier_workplan}}
                            </vs-td>

                            <template slot="expand">
                                <vs-table stripe search :data="tr.pg_requests" noDataText="No hay información">
                                     <div slot="header">
                                        <h4>Detalle</h4>
                                    </div>
                                    <template slot="thead">
                                        <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                                    </template>
                                    <template slot-scope="{data}">
                                        <vs-tr :key="index" v-for="(pg, index) in data">
                                            <vs-td>
                                                {{pg.product_model.product_brand.product_group.name}}
                                            </vs-td>
                                            <vs-td>
                                                {{pg.product_model.product_brand.brand}}
                                            </vs-td>
                                            <vs-td>
                                                {{pg.product_model.model}}
                                            </vs-td>
                                            <vs-td>
                                                {{pg.quantity}}
                                             </vs-td>
                                            <!-- <vs-td>
                                                {{pg.product_model.resource_impact !== null ? pg.product_model.resource_impact : "N/A"}}
                                            </vs-td> -->
                                            <vs-td>
                                                {{`${pg.product_model.capacity} ${pg.product_model.product_brand.product_group.capacity_units}`}}
                                            </vs-td>
                                            <vs-td>
                                                {{pg.product_model.degradation_per_year !== null ? pg.product_model.degradation_per_year : "N/A"}}
                                            </vs-td>
                                            <vs-td>
                                                {{pg.product_model.lifespan !== null ? (pg.product_model.lifespan * 100).toFixed(0) : "N/A"}}
                                            </vs-td>
                                            <vs-td>
                                                <vs-button size="samll" color="danger" @click="setTechSelected(pg)" icon="remove_red_eye"></vs-button>
                                            </vs-td>
                                        </vs-tr>
                                    </template>
                                </vs-table>
                            </template>
                        </vs-tr>    
                    </template>
                </vs-table>
            </vx-card>
        </div>

        <vs-popup id="serial-numbers" title="NÚMEROS DE SERIE" :active.sync="popupActiveSerialNumbers">
            <vx-card>
                <p style="word-break: break-all;">{{techSelected.serial_numbers !== null ?  techSelected.serial_numbers : "Los números de serie no han sido cargados."}}</p>
            </vx-card>
        </vs-popup>
    </div>
</template>

<script>

const columnsPrincipal = [
    { title: "SISTEMA O TECNOLOGÍA", key: "technology", sortable: false }
    , { title: "UBICACIÓN", key: "location", sortable: false }
    , { title: "PLAN DE TRABAJO", key: "description", sortable: false }
]

const columns = [
    { title: "GRUPO DE PRODUCTOS", key: "product_group", sortable: false }
    , { title: "MARCA", key: "brand", sortable: false }
    , { title: "MODELO", key: "model", sortable: false }
    , { title: "CANTIDAD", key: "quantity", sortable: false }
    // , { title: "IMPACTO AMBIENTAL", key: "saved", sortable: false }
    , { title: "CAPACIDAD", key: "capacity", sortable: false }
    , { title: "DEGRADACIÓN POR AÑO (%)", key: "degradation_per_year", sortable: false }
    , { title: "VIDA ÚTIL (AÑOS)", key: "lifespan", sortable: false }
    , { title: "NÚMEROS DE SERIE", key: "serial_numbers", sortable: false }
];

export default {
    name: "evidences-control",
    props: {
        projectFgLocations: { type: [Array], required: true }
    },
    data() {
        return {
            columns:columns,
            columnsPrincipal: columnsPrincipal,
            popupActiveSerialNumbers: false,
            techSelected: {}
        }
    },
    methods: {
        setTechSelected(item){
            this.techSelected = item;
            this.popupActiveSerialNumbers = true;
        }
    }
}
</script>

<style>
    #serial-numbers
    {
        z-index: 100000 !important;
    }
</style>