<script>
export default {
  name: "MetabaseDashboard",
  props: {
    dashboardId: {
      type: Number,
      required: true
    },
    params: {
      type: String
    }
  },
  data: () => ({
    iframeUrl: null
  }),
  async mounted () {
    const {data} = await axios.post(`/api/metabase/dashboard/${this.dashboardId}`, {
      params: this.params
    })
    this.iframeUrl = data.url
  }
}
</script>

<template>
  <iframe
      v-if="iframeUrl !== null"
      :src="iframeUrl"
      frameborder="0"
      class="w-full h-screen"
      allowtransparency
  ></iframe>
</template>

<style scoped>

</style>