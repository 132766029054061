<template>
    <div id="technologies-control">
        <div class="vx-col w-full mb-6">
            <vx-card title="Detalle de las tecnologías.">
                <p>Aquí podrás consultar las evidencias de la instalación de las tecnologías utilizadas en este proyecto.</p>
            </vx-card>
        </div>

        <div class="vx-col w-full mb-6">
            <vx-card>
                <vs-table stripe search :data="projectFgLocations" noDataText="No hay información">
                    <div slot="header">
                        <h4>Lista de instalaciones.</h4>
                    </div>
                    <template slot="thead">
                        <vs-th class="bold" v-for="(col, idx) in columnsPrincipal" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>
                                {{tr.family_group.name}}
                            </vs-td>
                            <vs-td>
                                {{tr.location.address.full_address}}
                            </vs-td>
                            <vs-td>
                                {{tr.supplier_workplan}}
                            </vs-td>

                            <template slot="expand">
                                <vx-card>
                                    <project-technology-evidences-page :id="tr.project_id" :fg_id="tr.id" :is-program="true" :selfAccessToken="AccessToken"></project-technology-evidences-page>
                                </vx-card>
                            </template>
                        </vs-tr>    
                    </template>
                </vs-table>
            </vx-card>
        </div>
    </div>
</template>

<script>
import ProjectTechnologyEvidencesPage from "@views/supplier/projects/ProjectTechnologyEvidencesPage";

const columnsPrincipal = [
    { title: "SISTEMA O TECNOLOGÍA", key: "technology", sortable: false }
    , { title: "UBICACIÓN", key: "location", sortable: false }
    , { title: "PLAN DE TRABAJO", key: "description", sortable: false }
]

export default {
    name: "technologies-control",
    props: {
        projectFgLocations: { type: [Array], required: true }
    },
    components: {
        ProjectTechnologyEvidencesPage
    },
    data() {
        return {
            columnsPrincipal: columnsPrincipal,
        }
    },
    methods: {

    }
}
</script>

<style>
    #serial-numbers
    {
        z-index: 100000 !important;
    }
</style>