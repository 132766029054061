<template>
    <div id="survey-control">
        <div v-if="survey.length > 0">
            <vx-card title="Encuesta de satisfacción.">
                <p>La encuesta de satisfacción de este proyecto ha sido respondida. A continuación encontrarás las respuestas sobre el desempeño y la atención de RedGirasol y el Proveedor. </p>
            </vx-card>

            <vs-table  ref="table" stripe :data="survey" noDataText="No hay datos disponibles" >
                <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between mt-4"></div>
                <template slot="thead">
                    <vs-th v-for="(col, idx) in columns_survey" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
                </template>

                <template slot-scope="{data}">
                    <template v-for="(tr, indextr) in data">
                        <vs-tr v-if="tr.type == 'SP_QUESTION'" :key="indextr"  :data="tr">
                            <vs-td>
                                {{getQuestionType(tr)}}
                            </vs-td>
                            <vs-td>
                                {{tr.question}} <br>
                                <small>{{tr.indications}}</small>
                            </vs-td>
                            <vs-td>
                                {{getYesOrNOAnswers(tr)}}
                            </vs-td>
                        </vs-tr>
                    </template>
                    <template v-for="(tr, indextr) in data">
                        <vs-tr v-if="tr.type == 'RG_QUESTION'" :key="indextr"  :data="tr">
                            <vs-td>
                                {{getQuestionType(tr)}}
                            </vs-td>
                            <vs-td>
                                {{tr.question}} <br>
                                <small>{{tr.indications}}</small>
                            </vs-td>
                            <vs-td>
                                {{getYesOrNOAnswers(tr)}}
                            </vs-td>
                        </vs-tr>
                    </template>
                </template>
            </vs-table>
        </div>

        <div v-else>
            <vx-card title="Encuesta de satisfacción">
                <p class="mb-6">Parece que aún no ha sido respondida la encuesta de satisfacción de este proyecto. Puedes enviar un recordatorio desde el botón "Enviar encuesta".</p>
                <vs-button color="black" @click.stop="sendSurveyEmails()">Enviar encuesta</vs-button>
            </vx-card>
        </div>
    </div>
</template>

<script>
const columns_survey = [
    { title: "PREGUNTA", key: "project", sortable: false}
    , { title: "ACTOR EVALUADO", key: "applicant", sortable: false}
    , { title: "RESPUESTA", key: "supplier", sortable: false}
];

const spQuestionIds = [64,65,66];

export default {
    name: "survey-control",
    props: {
        project_id: { type: [Number, String], required: true },
        client_id: { type: [Number, String], required: true }
    },
    data() {
        return {
            columns_survey: columns_survey,
            spQuestionIds: spQuestionIds,
            survey: []
        }
    },
    mounted() {
        this.getSurvey();
    },
    methods: {
        async sendSurveyEmails(){
            this.showLoading(true);
            try {
                let payload = [{ "pids" : [this.project_id]}];
                await this.publicApiPost(`/api/v1/applicant_satisfaction_survey/sendSurveyEmails`, payload);
                this.getProjectsList();
                this.warningNotif(
                    "Encuesta de satisfacción"
                    , "Se ha enviado un correo al Solicitante para el llenado de la encuesta de servicio, de la cual es necesaria su respuesta para poder realizar el pago de finiquito."
                    , 15000
                );
            } catch (error) {
                this.warn(error);
                this.failedOperationNotif(null, null);
            }
            this.showLoading(false);
        },

        async getSurvey(){
            this.showLoading(true);
            try{
                let res = await axios.get(`/api/applicant_satisfaction_survey/${this.project_id}/survey/${this.client_id}`);
                this.survey = res.data;
            }catch(err){
                this.warn(err);
                this.failedOperationNotif(null, null);
            }
            this.showLoading(false);
        },
        getYesOrNOAnswers(question)
        {
            let isQuestion = this.spQuestionIds.some(x => x == question.id);
            if(isQuestion){
                if(question.value == "5"){
                    return "Si";
                } else {
                    return "No";
                }
            } else { return question.value }
        },
        getQuestionType(question){
            if(question.type == "RG_QUESTION"){
                return "RedGirasol";
            } else if(question.type == "SP_QUESTION") {
                return "Proveedor";
            } else {
                return "--"
            }
        } 
    }
}
</script>

<style>

</style>